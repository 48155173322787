import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
// import { useStaticQuery, graphql } from "gatsby";

const SEO = ({
  description,
  lang,
  meta,
  url = "",
  title,
  image = "https://api.invozone.com/wp-content/uploads/2021/09/zunaira_-_javascript.png",
}) => {
  // const { site } = useStaticQuery(
  //   graphql`
  //     query {
  //       site {
  //         siteMetadata {
  //           siteName
  //           title
  //           description
  //           author
  //           gSiteVerification
  //         }
  //       }
  //     }
  //   `
  // )

  // const metaDescription = description || site?.siteMetadata?.description;
  // const defaultTitle = site?.siteMetadata?.title;
  const metaDescription = description;
  const defaultTitle = title;
  // const author = site.siteMetadata?.author

  // const schemaMarkup = {
  //   "@context": "https://schema.org",
  //   "@type": "Organization",
  //   name: "InvoZone",
  //   url: "https://invozone.com/",
  //   logo: "https://invozone.com/static/a8599fb809df99b4f872eaf613e21d53/58c6b/logo-hz-white.webp",
  //   alternateName: "Software Development Company",
  //   sameAs: [
  //     "https://web.facebook.com/InvoZone1/?_rdc=3&_rdr",
  //     "https://twitter.com/invozone?lang=en",
  //     "https://github.com/InvoZone",
  //     "https://www.instagram.com/invozone/?hl=en",
  //     "https://www.linkedin.com/company/invozone",
  //     "https://www.youtube.com/channel/UCamPgA6MxGvg2T38QY1wOSQ",
  //   ],
  //   contactPoint: [
  //     {
  //       "@type": "ContactPoint",
  //       telephone: "+1 (647) 548-7866",
  //       contactType: "technical support",
  //       email: "info@invozone.com",
  //       contactOption: ["TollFree", "HearingImpairedSupported"],
  //       areaServed: ["US", "CA", "GB"],
  //       availableLanguage: "en",
  //     },
  //   ],
  // };

  // const localBusinessSchema = {
  //   "@context": "https://schema.org",
  //   "@type": "LocalBusiness",
  //   name: "InvoZone",
  //   image:
  //     "https://invozone.com/static/ef4c1f0be0caee5a4596e5c2311d5e27/fc97f/InvoZone_Logo.webp",
  //   "@id": "https://invozone.com/#software-Development",
  //   url: "https://invozone.com/",
  //   telephone: "+1 (302) 289-8629",
  //   priceRange: "N/A",
  //   address: {
  //     "@type": "PostalAddress",
  //     streetAddress: "8 The Green Suite # 11684 Dover, DE 19901",
  //     addressLocality: "Dover",
  //     addressRegion: "DE",
  //     postalCode: "19901",
  //     addressCountry: "US",
  //   },
  //   geo: {
  //     "@type": "GeoCoordinates",
  //     latitude: 39.1566126,
  //     longitude: -75.5241898,
  //   },
  //   openingHoursSpecification: {
  //     "@type": "OpeningHoursSpecification",
  //     dayOfWeek: ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday"],
  //     opens: "09:00 AM",
  //     closes: "06:00 PM",
  //   },
  //   sameAs: [
  //     "https://www.facebook.com/InvoZone1/?_rdc=2&_rdr",
  //     "https://twitter.com/invozone?lang=en",
  //     "https://www.instagram.com/invozone/?hl=en",
  //     "https://www.youtube.com/channel/UCamPgA6MxGvg2T38QY1wOSQ",
  //     "https://www.linkedin.com/company/invozone/",
  //     "https://invozone.com/blog",
  //     "https://invozone.com/technews/",
  //   ],
  // };

  return (
    <Helmet
      // htmlAttributes={{ lang }}
      title={title}
      // titleTemplate={
      //   title?.includes(defaultTitle) ? null : `%s - ${defaultTitle}`
      // }
      // meta={[
      //   {
      //     name: `referrer`,
      //     content: "no-referrer-when-downgrade",
      //   },
      //   {
      //     name: `og:locale`,
      //     content: "en-us",
      //   },
      //   {
      //     name: `description`,
      //     content: metaDescription,
      //   },
      //   {
      //     property: `og:title`,
      //     content: title,
      //   },
      //   {
      //     property: `og:description`,
      //     content: metaDescription,
      //   },
      //   {
      //     property: `og:type`,
      //     content: `website`,
      //   },
      //   {
      //     property: `og:url`,
      //     content: url,
      //   },

      //   {
      //     name: `image`,
      //     content: image,
      //   },
      //   {
      //     property: "og:image",
      //     content: image,
      //   },
      //   {
      //     property: "og:image:width",
      //     content: 1200,
      //   },
      //   {
      //     property: "og:image:height",
      //     content: 600,
      //   },
      //   {
      //     property: "og:image:alt",
      //     content: title,
      //   },
      //   {
      //     name: `twitter:image:alt`,
      //     content: title,
      //   },
      //   {
      //     name: "twitter:card",
      //     content: "summary_large_image",
      //   },
      //   {
      //     name: `twitter:image`,
      //     content: image,
      //   },

      //   {
      //     name: `twitter:creator`,
      //     content: `@invozone`,
      //   },
      //   {
      //     name: `twitter:title`,
      //     content: title,
      //   },
      //   {
      //     name: `twitter:site`,
      //     content: `@invozone`,
      //   },

      //   {
      //     name: `twitter:description`,
      //     content: metaDescription,
      //   },
      //   {
      //     name: `twitter:url`,
      //     content: url,
      //   },

      //   {
      //     name: `robots`,
      //     content: `index, follow`,
      //   },
      //   {
      //     name: `robots`,
      //     content: `max-snippet:-1`,
      //   },
      //   {
      //     name: `googlebot`,
      //     content: `index, follow`,
      //   },
      //   {
      //     name: `googlebot-news`,
      //     content: `index, follow`,
      //   },
      //   {
      //     name: `googlebot-news`,
      //     content: `max-snippet:-1`,
      //   },
      //   {
      //     name: `keywords`,
      //     content: description,
      //   },
      // ]
      //   .concat(
      //     image
      //       ? [
      //           {
      //             property: "og:image",
      //             content: image,
      //           },
      //           {
      //             name: "twitter:card",
      //             content: "summary_large_image",
      //           },
      //         ]
      //       : [
      //           {
      //             name: "twitter:card",
      //             content: "summary",
      //           },
      //         ]
      //   )
      //   .concat(meta)}
    >
      <meta name="robots" content="noindex, nofollow" />
      {/* {" "}
      <script
        type="text/javascript"
        src="https://widget-invocom.invo.zone/widget.js"
        charSet="UTF-8"
      ></script>
      <script
        dangerouslySetInnerHTML={{
          __html: `
            window.injectWidget({
              APP_ID: "993fb2dc-5d95-482d-8878-966d49f5cfb6",
              APP_URL: "https://invozone.com",
              API_BASE_URL: "https://backend-invocom.invo.zone/api/v1",
            });
           `,
        }}
      /> */}

      {/* <script type="application/ld+json">{JSON.stringify(schemaMarkup)}</script> */}
      {/* <script type="application/ld+json">
        {JSON.stringify(localBusinessSchema)}
      </script> */}
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.arrayOf(PropTypes.object),
//   title: PropTypes.string.isRequired,
// };

export default SEO;
